import {createClient} from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url';
import { getFileAsset } from '@sanity/asset-utils'


const client = createClient({
    projectId: 'h2j5sc21',
    dataset: 'production',
    useCdn: true,
  });
const builder = imageUrlBuilder(client);
  
export function urlFor(source) {
    return builder.image(source);
}

export function urlForVideo(video) {
  try {
    const assetData = getFileAsset(video.asset._ref, client.config())
    return assetData.url;
  } catch (error) {
    console.error('Error fetching video asset:', error);
    return null;
  }
}