import React, { useState } from 'react';
import ProjectPropOne from './itemProp/ProjectPropOne';
import ProjectData from "../../data/project/ProjectData.json";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import ReactPaginate from 'react-paginate';

const AllData = ProjectData;


// {
//     "id": 2,
//     "image": "/images/project/project-2.png",
//     "title": "Digital Marketing",
//     "category": ["Logo", "Website", "Mobile"],
//     "excerpt": "A quick view of industry specific problems solved with design by the awesome team at Keystroke.",
//     "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
// },

const ProjectFive = ({colSize, parentClass, perPageShow, portfolios}) => {

    // const [projects] = useState(AllData.slice(0, 16));
    const [projects] = useState(portfolios.slice(0, 16));
    const [pageNumber, setPageNumber] = useState(0);

    const projectPerPage = perPageShow ? perPageShow : 6;
    const pageVisited = pageNumber * projectPerPage;

    const displayProjects = projects.slice(pageVisited, pageVisited + projectPerPage)
    .map((data) => (
        <div className="col" key={data.id}>
            <ProjectPropOne projectStyle="" portfolio={data}/>
        </div>
    ))

    const pageCount = Math.ceil(projects?.length / projectPerPage);

    const changePage = ({selected}) => {
        setPageNumber(selected);
    }

    return (
        <div className={`section section-padding-equal pt--200 pt_md--80 pt_sm--60 ${parentClass ? parentClass : ""}`}>
            <div className="container-fluid plr--30">
                <div className={`row row-cols-1 ${colSize ? colSize : "row-cols-sm-2 row-cols-xl-3"}`}>
                    {displayProjects}
                </div>
                <ReactPaginate
                previousLabel={<FaArrowLeft />}
                nextLabel={<FaArrowRight />}
                pageCount= {pageCount}
                onPageChange={changePage}
                containerClassName={"pagination"}
                previousLinkClassName={"prev"}
                nextLinkClassName={"next"}
                disabledClassName={"disabled"}
                activeClassName={"current"}
                />
            </div>
        </div>
    )
}

export default ProjectFive;