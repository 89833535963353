import React from 'react';
import FooterOne from '../common/footer/FooterOne';

import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CounterUpTwo from '../component/counterup/CounterUpTwo';
import TeamOne from '../component/team/TeamOne';
import VideoTwo from '../component/video/VideoTwo';

const Team = () => {

    return (
        <>
        <SEO title="The Team" />
        
            <main className="main-wrapper">
                
                <BcrumbBannerOne 
                title="Our Creative &amp; Innovative Experts"
                paragraph ="We're a team that's driven by innovation, creativity, and a relentless pursuit of excellence in everything we do."
                styleClass="thumbnail-2"
                mainThumb="/images/banner/banner-thumb-2.png"
                />
                <CounterUpTwo />
                <TeamOne />
                {/* <VideoTwo /> */}
                <CtaLayoutOne />
                <FooterOne parentClass="" title="TechBias Innovations"/>
            </main>
        </>
    )
}

export default Team;