import React from 'react';
import FooterOne from '../common/footer/FooterOne';

import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';



const AboutUs = () => {

    return (
        <>
        <SEO title="About us | TechBias" />
        
            <main className="main-wrapper">
                {/*  */}
                
                <BcrumbBannerOne 
                title="Driving digital business growth for our clients."
                paragraph ="TechBias Innovations is a software development company that creates custom digital solutions to drive business growth."
                styleClass="thumbnail-4"
                mainThumb="/images/banner/banner-thumb-3.png"
                />
                <AboutFour/>
                <AboutThree/>
                <AboutFive/>
                {/* <ProcessOne /> */}
                <CtaLayoutOne />
                <FooterOne parentClass="" title="TechBias Innovations"/>
            </main>
        </>
    )
}

export default AboutUs;