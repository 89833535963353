import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Innovation",
        para: "We constantly strive to push the boundaries of what is possible through creative problem-solving and cutting-edge technology."
    },
    {
        id: 2,
        title: "Customer Focus",
        para: "We put our clients first, tailoring our solutions to their specific needs and providing exceptional support throughout the project."
    },
    {
        id: 3,
        title: "Integrity",
        para: "We hold ourselves to the highest ethical standards and prioritize transparency, honesty, and accountability in all our interactions."
    },
    {
        id: 4,
        title: "Teamwork",
        para: "We value collaboration and believe that working together as a cohesive team leads to the best outcomes for our clients and our company."
    },
    {
        id: 5,
        title: "Continuous Learning",
        para: "We prioritize ongoing training to stay ahead of technology and keep our team skilled. This keeps us sharp for exceptional client value."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Values"
                    title="Why should you work with us?"
                    description="TechBias Innovations delivers advanced software solutions with exceptional support. Let us bring your vision to life."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;