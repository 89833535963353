import React, { useEffect, useState } from 'react';
import FooterOne from '../common/footer/FooterOne';

import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectFive from '../component/project/ProjectFive';
import { sanityClient } from '../common/SanityClient';

const ProjectGridFour = () => {

    const [portfolios, setPortfolio] = useState();
    const query = `{
        "portfolio": *[_type == "portfolio"] {
          title, slug, 
          "body": description,
          "excerpt":brief,
          image,
          "category": ["Logo", "Website", "Mobile"],
        }
      }`;

    useEffect(() => {

        (async function () {
            const posts = await sanityClient.fetch(query);
            setPortfolio(posts.portfolio);
            return posts;
        })();
    }, []);

    console.log("Portfolios:", portfolios);

    return (
        <>
            <SEO title="Project Full Width Three Column" />

            <main className="main-wrapper">


                <BcrumbBannerOne
                    title="Our Projects"
                    paragraph="A quick view of industry specific problems solved with design by the awesome team at Abstrak."
                    styleClass=""
                    mainThumb="/images/banner/banner-thumb-1.png"
                />
                {portfolios &&
                    <ProjectFive portfolios={portfolios} />
                }
                <CtaLayoutOne />
                <FooterOne parentClass="" title="TechBias Innovations" />
            </main>
        </>
    )
}

export default ProjectGridFour;