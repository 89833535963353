import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';

import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import { slugify } from '../utils';
import ProjectData from "../data/project/ProjectData.json";
import VideoOne from '../component/video/VideoOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import { sanityClient } from '../common/SanityClient';
import { urlFor } from '../common/UrlBuilder';

const allProjectData = ProjectData;

const ProjectDetails = () => {

    const params = useParams();
    const projectSlug = params.slug;
    const portfolioSlug = "4x90-studio";

    const [portfolios, setPortfolio] = useState();
    const query = `{"portfolio": *[_type == "portfolio" && slug.current == "${projectSlug}"] {
          title, slug, 
          "body": description,
          "excerpt":brief,
          image,
          "category": ["Logo", "Website", "Mobile"],
          link
        }
      }`;

    useEffect(() => {

        (async function () {
            const posts = await sanityClient.fetch(query);
            setPortfolio(posts.portfolio);
            return posts;
        })();
    }, [portfolios]);
    portfolios && console.log("Portfolios:", portfolios[0]);
    console.log("Slug:", projectSlug);
    console.log("Slug:", projectSlug === portfolioSlug);


    // const getProjectData = allProjectData.filter(data => slugify(data.title) === projectSlug);
    // const detailsProject = getProjectData[0];

    const detailsProject = portfolios && portfolios[0];


    return (

        <>
            <SEO title="Project Details" />

            {detailsProject &&
                <main className="main-wrapper">

                    <BcrumbBannerTwo
                        title={detailsProject.title}
                        paragraph={detailsProject.excerpt}
                        mainThumb={urlFor(detailsProject.image)}
                    />
                    <section className="section-padding single-portfolio-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="section-heading heading-left mb-0">
                                        <span className="subtitle">
                                            {detailsProject.category.map((cat, i) => (
                                                <span key={i}>{cat}</span>
                                            )
                                            )}
                                        </span>
                                        <h3 className="title">{detailsProject.title}</h3>
                                    </div>
                                    {/* {detailsProject.body.map((para, index) => (
                                        <p key={index} dangerouslySetInnerHTML={{ __html: para }}></p>
                                    ))} */}
                                    <p>{detailsProject.body}</p>
                                    <Link to={detailsProject.link} target='_blank' className="tbias-btn btn-fill-primary">Live Project</Link>
                                </div>
                                <div className="col-lg-6 offset-xl-1">
                                    <div className="why-choose-us">
                                        <div className="section-heading heading-left">
                                            <h3 className="title">We delivered</h3>
                                            <p>In today’s fast-paced digital landscape, the evolution of technology has created a world where transparency and connectivity are at the forefront. This shift presents both new challenges and opportunities for businesses of all sizes.</p>
                                        </div>
                                        <Accordion defaultActiveKey="1">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header><FaCompress /> Strategy</Accordion.Header>
                                                <Accordion.Body>
                                                We craft tailored strategies that align with your goals, ensuring every step drives growth and success.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header><FaCode /> Design</Accordion.Header>
                                                <Accordion.Body>
                                                Our designs combine creativity and functionality to create engaging, user-friendly experiences that enhance your brand.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header><FaGlobe /> Development</Accordion.Header>
                                                <Accordion.Body>
                                                We build robust, scalable solutions that bring your ideas to life, ensuring they are both functional and future-ready.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <VideoOne /> */}
                    <CtaLayoutOne />
                    <FooterOne parentClass="" title="TechBias Innovations" />
                </main>
            }
        </>
    )
}

export default ProjectDetails;