import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-2.png"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">Our Team</span>
                        {/* <h2>Collaboration breeds excellence, <br></br> Meet our team.</h2>   */}
                        <h2>Together, <br></br> we can achieve greatness.</h2>  
                        <p>Our passionate team of developers, designers, and marketers collaborates to deliver exceptional results for businesses. With diverse skill sets and experience, we exceed expectations and drive success.</p>
                        <Link to={process.env.PUBLIC_URL + "/team"} className="tbias-btn btn-large btn-fill-primary">Our Team</Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;